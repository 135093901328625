import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { APICustomer } from './api-customer.interface';
import { Observable, of } from 'rxjs';
import { Customer } from './customer.interface';
import { catchError, map, pluck } from 'rxjs/operators';
import { apiCustomerToCustomer } from './api-customer-to-customer.function';
import { PropertyService } from 'services/*';

@Injectable({ providedIn: 'root' })
export class CustomerService {
  currency: string = null;
  constructor(
    @Inject(HttpClient) private http: HttpClient,
    @Inject(PropertyService) private readonly propertyService: PropertyService
  ) {}

  getCustomer(params: Record<string, string>): Observable<Customer> {
    return this.http
      .get<{ body: APICustomer }>(
        `${environment.url_api}/motor/propiedades/${this.propertyService.property.id}/clientes`,
        { params }
      )
      .pipe(pluck('body'), map(apiCustomerToCustomer));
  }
}
