import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { from, Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { PropertyService } from 'services/*';
import { ReservationService } from '../services/reservation/reservation.service';

@Injectable({
  providedIn: 'root',
})
export class ReservationGuard implements CanActivate {
  constructor(
    @Inject(ReservationService)
    private readonly reservation: ReservationService,
    @Inject(Router) private readonly router: Router,
    @Inject(PropertyService) private readonly propertyService: PropertyService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      this.reservation.roomItems.length ||
      this.reservation.extraItems.length
    ) {
      return true;
    } else {
      const queryParams = {
        ...route.queryParams,
        ...(!this.propertyService.property.configuredDomain && {
          token: this.propertyService.property.token,
        }),
      };
      return from(
        this.router.navigate(['/reservation/rooms'], {
          queryParams,
          queryParamsHandling: 'merge',
          fragment: 'habitaciones',
        })
      ).pipe(mapTo(false));
    }
  }
}
