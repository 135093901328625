import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-property-not-configured',
  template: `
    <img
      src="/assets/images/motor-no-configurado.jpg"
      alt="Motor no configurado"
    />
  `,
  host: {
    class: 'block h-screen w-full flex items-center justify-center',
  },
})
export class PropertyNotConfiguredComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
