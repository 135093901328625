<ng-content select="[headerContainer]"></ng-content>

<button
  type="button"
  class="w-full bg-accent-500 py-2 px-4 text-white focus:outline-none"
  (click)="showDetails()"
>
  <div class="flex justify-center items-center w-full">
    <p class="text-lg mr-4">Detalle de reserva</p>
    <mat-icon [@rotate]="iconAnimation">expand_more</mat-icon>
  </div>

  <p class="text-xs text-center">
    {{ checkIn | date: "longDate" }} - {{ checkOut | date: "longDate" }} ({{
    numberOfNights | i18nPlural: numberOfNightsPluralMap }})
  </p>
</button>

<div *ngIf="details">
  <div class="flex justify-between bg-gray-100 py-2 px-4">
    <div>
      <p class="font-medium">Descuento</p>
      <p class="text-gray-700">
        {{ selectedCurrencyName }} {{ reservation.discount | currency:
        "":"":currencyDecimalsDigitsInfo }}
      </p>
    </div>
    <div>
      <p class="font-medium">Sub total</p>
      <p class="text-gray-700">
        {{ selectedCurrencyName }} {{ reservation.subTotal | currency:
        "":"":currencyDecimalsDigitsInfo }}
      </p>
    </div>

    <div>
      <p class="font-medium">
        IVA
        <mat-icon
          inline="true"
          matTooltip="El impuesto solo aplica a residentes nacionales"
          >info</mat-icon
        >
      </p>
      <p>
        {{ selectedCurrencyName }} {{ reservation.tax | currency:
        "":"":currencyDecimalsDigitsInfo }}
      </p>
    </div>

    <div>
      <p class="font-medium">Total</p>
      <p class="text-gray-700">
        {{ selectedCurrencyName }} {{ reservation.total | currency:
        "":"":currencyDecimalsDigitsInfo }}
      </p>
    </div>
  </div>
  <div
    class="px-4 py-2 space-y-4 bg-white overflow-scroll overflow-x-hidden max-h-[20vh]"
    *ngIf="roomItems.length !== 0 || extraItems.length !== 0"
  >
    <ul class="text-gofeels-blue-gray space-y-2">
      <li
        *ngFor="let item of roomItems; trackBy: trackById"
        class="flex items-center flex-space-x-2"
      >
        <div class="text-gofeels-blue-gray">
          <p class="font-medium">{{ item.roomName }}</p>
          <p>({{ item.guests }} huéspedes)</p>
        </div>
        <span class="flex-1"></span>
        <p class="text-gray-700">
          {{ selectedCurrencyName }} {{ item.price | currency:
          "":"":currencyDecimalsDigitsInfo }}
        </p>
        <button
          matTooltip="Quitar de la reserva"
          (click)="deleteRoomItem(item.id)"
          mat-icon-button
          color="accent"
        >
          <mat-icon> cancel </mat-icon>
        </button>
      </li>
      <li
        *ngFor="let item of extraItems; trackBy: trackById"
        class="flex items-center flex-space-x-2"
      >
        <div class="text-gofeels-blue-gray">
          <p class="font-medium">{{ item.extraName }}</p>
          <p>
            ({{ item.guests }} personas - {{ item.date | date: 'MMM d, y' |
            titlecase }} {{ item.time ? ' - ' + item.time : '' }})
          </p>
        </div>
        <span class="flex-1"></span>
        <p class="text-gray-700">
          {{ selectedCurrencyName }} {{ item.price | currency:
          '':'':currencyDecimalsDigitsInfo }}
        </p>
        <button
          matTooltip="Quitar de la reserva"
          (click)="deleteExtraItem(item.id)"
          mat-icon-button
          color="accent"
        >
          <mat-icon> cancel </mat-icon>
        </button>
      </li>
    </ul>
  </div>
</div>
