import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { PropertyService } from 'services/*';
import { Property } from 'src/app/services/property/property.interface';
import { ReservationService } from 'src/app/services/reservation/reservation.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-hero',
  template: `
    <div id="disponibilidad" class="relative h-[270px] md:h-[538px]">
      <ngb-carousel [showNavigationArrows]="false">
        <ng-template *ngFor="let image of property.images" ngbSlide>
          <div class="h-[270px] md:h-[538px] relative">
            <div
              class="absolute bg-gradient-to-b to-transparent via-transparent from-[#0000009c] inset-0"
            ></div>
            <img [src]="image" class="w-full h-full  object-cover" />
          </div>
        </ng-template>
      </ngb-carousel>
      <div class="absolute inset-0">
        <div
          [ngClass]="{
            'md:pt-32 justify-between': property.showHeroTitle,
            'justify-center items-center': !property.showHeroTitle
          }"
          class="relative max-w-4xl mx-auto py-12 h-full flex flex-col "
        >
          <div
            *ngIf="property.showHeroTitle"
            class="text-white font-medium w-full text-center md:text-left px-8"
          >
            <p class="text-2xl md:text-3xl text-shadow-md">Bienvenido a</p>
            <h1 class="text-4xl md:text-6xl text-shadow-md">
              {{ property.name }}
            </h1>
            <p class="text-xl text-shadow-md truncate mt-2 md:mt-6">
              <i class="fas fa-map-marker-alt"></i>
              {{ property.address }}, {{ property.cityName }},
              {{ property.countryName }}
            </p>
          </div>

          <div
            [ngClass]="{
              'absolute md:static top-56 w-full px-8': property.showHeroTitle
            }"
            class=""
          >
            <form
              (ngSubmit)="search()"
              [formGroup]="form"
              class="flex flex-col md:flex-row mx-auto p-4 items-stretch md:space-x-4 bg-white w-full md:w-min"
            >
              <mat-form-field>
                <mat-label>Buscar entre rango de fechas</mat-label>
                <mat-date-range-input [rangePicker]="picker" [min]="today">
                  <input
                    (focus)="picker.open()"
                    formControlName="checkIn"
                    matStartDate
                    placeholder="Check in"
                  />
                  <input
                    formControlName="checkOut"
                    (focus)="picker.open()"
                    matEndDate
                    placeholder="Check out"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error [matTooltip]="errors" class="truncate">
                  {{ errors }}
                </mat-error>
              </mat-form-field>
              <button
                [disabled]="form.invalid"
                mat-flat-button
                color="accent"
                class="uppercase"
              >
                Buscar disponibilidad
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class HeroComponent implements OnInit {
  form: UntypedFormGroup = this.fb.group({
    checkIn: [
      this.checkInQueryParam
        ? moment(this.checkInQueryParam, 'YYYY-MM-DD')
        : moment(),
      Validators.required,
    ],
    checkOut: [
      this.checkOutQueryParam
        ? moment(this.checkOutQueryParam, 'YYYY-MM-DD')
        : moment().add(1, 'day'),
      Validators.required,
    ],
  });

  get errors(): string {
    const checkInErrors = this.form?.get('checkIn').errors || {};
    const checkOutErrors = this.form?.get('checkOut').errors || {};

    return [
      ...Object.keys(checkInErrors).map((key) => {
        switch (key) {
          case 'required':
            return 'Check in es obligatorio';
          case 'matDatepickerParse':
            return 'Formato de fecha de check in inválido';
        }
      }),
      ...Object.keys(checkOutErrors).map((key) => {
        switch (key) {
          case 'required':
            return 'Check out es obligatorio';
          case 'matDatepickerParse':
            return 'Formato de fecha de check out inválido';
          case 'sameDay':
            return 'El día de check out no puede ser el mismo día del check in';
        }
      }),
    ].join('. ');
  }

  private get checkInQueryParam(): string {
    return this.route.snapshot.queryParamMap.get('checkIn');
  }

  private get checkOutQueryParam(): string {
    return this.route.snapshot.queryParamMap.get('checkOut');
  }

  property: Property = this.propertyService.property;

  today: moment.Moment = moment();

  constructor(
    @Inject(PropertyService) private readonly propertyService: PropertyService,
    @Inject(UntypedFormBuilder) private readonly fb: UntypedFormBuilder,
    @Inject(ReservationService)
    private readonly reservation: ReservationService,
    @Inject(Router) private readonly router: Router,
    @Inject(ActivatedRoute) private readonly route: ActivatedRoute,
    @Inject(GoogleTagManagerService)
    private readonly googleTagManager: GoogleTagManagerService
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((value) => {
      const {
        checkIn,
        checkOut,
      }: { checkIn: moment.Moment; checkOut: moment.Moment } = value;
      const { sameDay, ...restErrors } = this.form.get('checkOut').errors || {};
      if (checkIn && checkOut && !checkIn.isSame(checkOut)) {
        if (Object.keys(restErrors).length >= 1) {
          this.form.get('checkOut').setErrors({
            ...restErrors,
          });
        } else {
          this.form.get('checkOut').setErrors(null);
        }
      } else if (checkIn && checkOut && checkIn.isSame(checkOut)) {
        this.form.get('checkOut').setErrors({
          ...restErrors,
          sameDay: true,
        });
      }
    });
  }

  search(): void {
    this.googleTagManager.pushTag({
      event: 'busqueda-disponibilidad',
      pageName: this.route.url,
    });
    this.reservation.roomItems = [];
    this.reservation.extraItems = [];
    this.reservation.coupon = null;
    const {
      checkIn,
      checkOut,
    }: { checkIn: moment.Moment; checkOut: moment.Moment } = this.form.value;
    const queryParams = {
      ...this.route.snapshot.queryParams,
      checkIn: checkIn.format('YYYY-MM-DD'),
      checkOut: checkOut.format('YYYY-MM-DD'),
    };
    this.router.navigate(['/reservation/rooms'], {
      fragment: 'habitaciones',
      queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
