import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { from, Observable } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';
import { PropertyService } from 'services/*';
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root',
})
export class PropertyWithDomainGuard implements CanActivate {
  constructor(
    @Inject(PropertyService) private readonly propertyService: PropertyService,
    @Inject(Router) private readonly router: Router,
    @Inject(ActivatedRoute) private readonly route: ActivatedRoute
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.propertyService.property.configuredDomain) {
      const checkIn = (() => {
        if (!route.queryParamMap.has('checkIn')) {
          return moment().format('YYYY-MM-DD');
        }
        const checkIn = route.queryParamMap.get('checkIn');
        if (moment(checkIn, 'YYYY-MM-DD').isBefore(moment(), 'day')) {
          return moment().format('YYYY-MM-DD');
        }
        return checkIn;
      })();
      const checkOut = (() => {
        if (!route.queryParamMap.has('checkOut')) {
          return moment(checkIn, 'YYYY-MM-DD')
            .add(1, 'day')
            .format('YYYY-MM-DD');
        }
        const checkOut = route.queryParamMap.get('checkOut');
        if (
          moment(checkOut, 'YYYY-MM-DD').isSameOrBefore(
            moment(checkIn, 'YYYY-MM-DD'),
            'day'
          )
        ) {
          return moment(checkIn, 'YYYY-MM-DD')
            .add(1, 'day')
            .format('YYYY-MM-DD');
        }
        return checkOut;
      })();
      return from(
        this.router.navigate(['/reservation', 'rooms'], {
          queryParams: { checkIn, checkOut, ...route.queryParams },
        })
      );
    }
    return true;
  }
}
