import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  getCSS(primaryColor: string, accentColor: string) {
    return this.http.get(`${environment.url_api}/motor/tema`, {
      observe: 'body',
      responseType: 'text',
      params: {
        color_primario: primaryColor,
        color_acentuado: accentColor,
      },
    });
  }

  addCSSToDOM(css: string) {
    const style = this.document.createElement('style');
    style.appendChild(this.document.createTextNode(css));
    this.document.head.appendChild(style);
  }
}
