import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { PropertyService } from 'services/*';
import { Confirmer } from 'src/app/interfaces';
import { ReservationComponent } from 'src/app/reservation/reservation.component';
import { getTotalRatePrice } from 'src/app/services/reservation/get-total-rate-price.function';
import { ReservationService } from 'src/app/services/reservation/reservation.service';
import { CONFIRMER } from 'src/app/tokens';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-reservation-details-panel-sticky',
  templateUrl: './reservation-details-panel.component-sticky.html',

  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotate', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(180deg)' })),
      transition('rotated => default', animate('400ms ease-out')),
      transition('default => rotated', animate('400ms ease-in')),
    ]),
  ],
})
export class ReservationDetailsPanelStickyComponent implements OnInit {
  iconAnimation = 'default';
  details = false;

  numberOfNightsPluralMap: Record<string, string> = {
    '=1': 'Una noche',
    other: '# noches',
  };

  get selectedCurrencyName(): string {
    return this.propertyService.property.currency?.name || '';
  }

  get selectedCurrencyDecimals(): number {
    return this.propertyService.property.currency?.decimals || 0;
  }

  get currencyDecimalsDigitsInfo(): string {
    return `1.${this.selectedCurrencyDecimals}-${this.selectedCurrencyDecimals}`;
  }

  get openingTime(): string {
    return this.propertyService.property.openingTime.slice(0, 5);
  }

  get checkIn(): string {
    return this.reservation.checkIn;
  }

  get checkOut(): string {
    return this.reservation.checkOut;
  }

  get numberOfNights(): number {
    return moment(this.checkOut, 'YYYY-MM-DD').diff(
      moment(this.checkIn, 'YYYY-MM-DD'),
      'day'
    );
  }

  get roomItems(): {
    roomName: string;
    guests: number;
    price: number;
    id: number;
  }[] {
    return this.reservation.roomItems.map(({ guests, name, price, id }) => ({
      id,
      guests,
      price,
      roomName: name,
    }));
  }

  get extraItems(): {
    extraName: string;
    guests: number;
    price: number;
    id: number;
    date: string;
    time: string;
  }[] {
    return this.reservation.extraItems.map(
      ({ guests, name, price, id, date, time }) => ({
        id,
        guests,
        price,
        date,
        extraName: name,
        time,
      })
    );
  }

  trackById = (index: number, item: any) => item.id;

  constructor(
    @Inject(PropertyService) private readonly propertyService: PropertyService,
    @Inject(ReservationService) public readonly reservation: ReservationService
  ) {}

  ngOnInit(): void {
    combineLatest([this.reservation.roomItems$, this.reservation.extraItems$])
      .pipe(map(([roomItems, extraItems]) => [...roomItems, ...extraItems]))
      .subscribe((items) => {
        if (items.length === 1) {
          this.showDetails();
        }
      });
  }

  showDetails(): void {
    this.iconAnimation =
      this.iconAnimation === 'default' ? 'rotated' : 'default';
    this.details = !this.details;
  }

  deleteRoomItem(id: number): void {
    this.reservation.deleteRoomItem(id);
  }

  deleteExtraItem(id: number): void {
    this.reservation.deleteExtraItem(id);
  }
}
