import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

export type CreateSessionBody = {
  ip_address?: string;
};

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(private readonly http: HttpClient) {}

  createSession(propertyId: number, body: CreateSessionBody) {
    return this.http.post<any>(
      `${environment.url_api}/propiedades/${propertyId}/booking-engine-sessions`,
      body
    );
  }
}
