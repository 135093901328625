import { inject, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

export const IP = new InjectionToken<Observable<string | null>>(
  'IP address of the client',
  {
    factory: () => {
      const http = inject(HttpClient);
      return http
        .get<{ ip: string }>('https://api.ipify.org/?format=json')
        .pipe(
          catchError(() => of({ ip: null })),
          map(({ ip }) => ip),
          shareReplay(1)
        );
    },
  }
);
