<div class="p-4 rounded border space-y-4">
  <p class="text-xl text-gray-700 font-medium">Detalle de reserva</p>

  <div class="text-gofeels-blue-gray flex items-baseline space-x-1">
    <i class="far fa-calendar"></i>
    <div>
      <p>
        {{ checkIn | date: 'longDate' }} - {{ checkOut | date: 'longDate' }}
      </p>
      <p>({{ numberOfNights | i18nPlural: numberOfNightsPluralMap }})</p>
    </div>
  </div>

  <mat-divider></mat-divider>
  <div class="space-y-4">
    <div class="space-y-2">
      <p class="text-gray-700">Habitaciones</p>

      <ul class="text-gofeels-blue-gray space-y-2">
        <li
          *ngFor="let item of roomItems; trackBy: trackById"
          class="flex items-center flex-space-x-2"
        >
          <div>
            <p class="font-medium">{{ item.roomName }}</p>
            <p>({{ item.guests }} huéspedes)</p>
          </div>
          <span class="flex-1"></span>
          <p class="text-gray-700">
            {{ selectedCurrencyName }}
            {{ item.price | currency: '':'':currencyDecimalsDigitsInfo }}
          </p>
          <button
            *ngIf="!readonly"
            matTooltip="Quitar de la reserva"
            (click)="deleteRoomItem(item.id)"
            mat-icon-button
            color="accent"
          >
            <mat-icon> cancel</mat-icon>
          </button>
        </li>
      </ul>
    </div>
    <mat-divider></mat-divider>

    <div class="space-y-2">
      <p class="text-gray-700">Servicio extra o actividad</p>

      <ul class="text-gofeels-blue-gray space-y-2">
        <li
          *ngFor="let item of extraItems; trackBy: trackById"
          class="flex items-center flex-space-x-2"
        >
          <div>
            <p class="font-medium">{{ item.extraName }}</p>
            <p>
              ({{ item.guests }} personas -
              {{ item.date | date: 'MMM d, y' | titlecase }}
              {{ item.time ? ' - ' + item.time : '' }})
            </p>
          </div>
          <span class="flex-1"></span>
          <p class="text-gray-700">
            {{ selectedCurrencyName }}
            {{ item.price | currency: '':'':currencyDecimalsDigitsInfo }}
          </p>
          <button
            *ngIf="!readonly"
            matTooltip="Quitar de la reserva"
            (click)="deleteExtraItem(item.id)"
            mat-icon-button
            color="accent"
          >
            <mat-icon> cancel</mat-icon>
          </button>
        </li>
      </ul>
    </div>
  </div>

  <mat-divider></mat-divider>
  <ul class="text-gofeels-blue-gray space-y-2">
    <li *ngIf="discount" class="flex justify-between items-start">
      <p>Descuento</p>
      <p class="text-gray-700">
        {{ selectedCurrencyName }}

        {{ discount | currency: '':'':currencyDecimalsDigitsInfo }}
      </p>
    </li>

    <li class="flex justify-between items-start">
      <p>Sub total</p>
      <p class="text-gray-700">
        {{ selectedCurrencyName }}

        {{ reservation.subTotal | currency: '':'':currencyDecimalsDigitsInfo }}
      </p>
    </li>
    <li class="flex justify-between items-start">
      <p>
        IVA
        <mat-icon
          inline="true"
          matTooltip="El impuesto solo aplica a residentes nacionales"
          >info
        </mat-icon>
      </p>
      <p>
        {{ selectedCurrencyName }}

        {{ reservation.tax | currency: '':'':currencyDecimalsDigitsInfo }}
      </p>
    </li>
    <li class="flex justify-between items-start">
      <p>Total</p>
      <p class="text-gray-700">
        {{ selectedCurrencyName }}

        {{ reservation.total | currency: '':'':currencyDecimalsDigitsInfo }}
      </p>
    </li>
  </ul>
  <mat-divider></mat-divider>
  <ng-content select="[couponContainer]"></ng-content>
  <ul class="text-gofeels-blue-gray space-y-2">
    <li class="flex justify-between items-start">
      <p>Confirmación de reserva a pagar</p>
      <p class="text-gray-700">
        {{ selectedCurrencyName }}
        {{
          (reservation.roomsTotal * depositPercentage) / 100 +
            reservation.extrasTotal | currency: '':'':currencyDecimalsDigitsInfo
        }}
      </p>
    </li>
  </ul>
  <ng-content select="[footerContainer]"></ng-content>
</div>
<div *ngIf="propertyHasSchedules" class="p-4 space-y-4">
  <p class="text-xl text-gray-700">Horarios</p>

  <ul class="text-gofeels-blue-gray space-y-2">
    <li class="flex justify-between items-start">
      <p>Check in</p>
      <p>{{ checkInTime }}</p>
    </li>
    <li class="flex justify-between items-start">
      <p>Check out</p>
      <p>{{ checkOutTime }}</p>
    </li>

    <li class="flex justify-between items-start">
      <p>Apertura de recepción</p>
      <p>{{ openingTime }}</p>
    </li>
    <li class="flex justify-between items-start">
      <p>Cierre de recepción</p>
      <p>{{ closingTime }}</p>
    </li>
  </ul>
</div>
<mat-divider></mat-divider>
