import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import * as moment from 'moment';
import { from, Observable } from 'rxjs';
import { map, mapTo, tap } from 'rxjs/operators';
import { PropertyService } from 'services/*';
import { ReservationService } from '../services/reservation/reservation.service';

@Injectable({
  providedIn: 'root',
})
export class SuccededReservationGuard implements CanActivate {
  constructor(
    @Inject(ReservationService)
    private readonly reservation: ReservationService,
    @Inject(PropertyService) private readonly propertyService: PropertyService,
    @Inject(Router) private readonly router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (route.queryParamMap.has('id')) {
      return this.reservation
        .getReservationDetails(
          this.propertyService.property.token,
          parseInt(route.queryParamMap.get('id'), 10)
        )
        .pipe(
          tap((reservationDetails) => {
            this.reservation.details = reservationDetails;
          }),
          map(() => true)
        );
    } else {
      return from(this.router.navigateByUrl('/reservation/rooms')).pipe(
        mapTo(false)
      );
    }
  }
}
