import { Component, Inject, OnInit } from '@angular/core';
import { PropertyService } from 'services/*';
import { Property } from 'src/app/services/property/property.interface';
import * as moment from 'moment';

@Component({
  selector: 'app-toolbar',
  template: `
    <div class="bg-primary-500 p-2">
      <div
        class="
          h-5
          max-w-7xl
          flex
          justify-end
          text-white text-sm
          items-center
          space-x-4
          mx-auto
        "
      >
        <a href="mailto:{{ property.email }}" class="hover:underline">
          {{ property.email }}
        </a>
        <a href="tel:{{ property.firstPhoneNumber }}" class="hover:underline">
          {{ property.firstPhoneNumber.trim() }}
        </a>
        <ng-container *ngIf="property.secondPhoneNumber">
          <span class="hidden md:block"> / </span>
          <a
            href="tel:{{ property.secondPhoneNumber }}"
            class="hover:underline hidden md:block"
          >
            {{ property.secondPhoneNumber.trim() }}
          </a>
        </ng-container>
        <!-- <div>
              <a routerLink="." class="hover:underline">Es</a>/
              <a routerLink="." class="hover:underline">En</a>
            </div> -->
        <div class="space-x-2 hidden md:flex">
          <a
            *ngFor="let socialNetwork of property.socialNetworks"
            [href]="socialNetwork.url"
            target="_blank"
          >
            <img [src]="socialNetwork.iconUrl" class="w-4 h-4 inline-block" />
          </a>
        </div>
      </div>
    </div>
    <div class="bg-accent-500 py-2 px-8">
      <div class="max-w-7xl mx-auto flex justify-between items-center h-16">
        <a
          *ngIf="['/', '/reservation/rooms'].includes(homeUrl); else ownWebsite"
          [routerLink]="homeUrl"
          [queryParams]="homeQueryParams"
          queryParamsHandling="merge"
        >
          <img class="max-h-16" [src]="property.logo" alt="Logo" />
        </a>

        <ng-template #ownWebsite>
          <a [href]="homeUrl">
            <img class="max-h-16" [src]="property.logo" alt="Logo" />
          </a>
        </ng-template>

        <ng-content select="nav"></ng-content>
        <ng-content select="div"></ng-content>
      </div>
    </div>
  `,
})
export class ToolbarComponent implements OnInit {
  property: Property = this.propertyService.property;

  get homeUrl(): string {
    if (this.property.configuredDomain) {
      return '/';
    }

    if (this.property.website) {
      return this.property.website;
    }

    return '/reservation/rooms';
  }

  get homeQueryParams(): Record<string, string> {
    return this.property.configuredDomain
      ? {}
      : {
          token: this.property.token,
          checkIn: moment().format('YYYY-MM-DD'),
          checkOut: moment().add(1, 'day').format('YYYY-MM-DD'),
        };
  }

  constructor(
    @Inject(PropertyService) private readonly propertyService: PropertyService
  ) {}

  ngOnInit(): void {}
}
