import { APICustomer } from './api-customer.interface';
import { Customer } from './customer.interface';

export function apiCustomerToCustomer(apiCustomer: APICustomer): Customer {
  return {
    id: apiCustomer.id,
    rut: apiCustomer.rut,
    countryId: apiCustomer.pais_id,
    customerTypeId: apiCustomer.tipo_cliente_id,
    email: apiCustomer.email,
    phone: apiCustomer.telefono,
    regionId: apiCustomer.region_id,
    address: apiCustomer.direccion,
    ...(apiCustomer.tipo_cliente_id === 2 && {
      businessName: apiCustomer.nombre,
      order: apiCustomer.giro,
    }),
    ...(apiCustomer.tipo_cliente_id === 1 && {
      firstName: apiCustomer.nombre,
      lastName: apiCustomer.apellido,
    }),
    city: apiCustomer.ciudad,
  };
}
