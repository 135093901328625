import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { PropertyCurrency } from '../services/property/property-currency.interface';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { parseInt } from 'lodash-es';
import { PropertyService } from 'services/*';
import { ReservationService } from '../services/reservation/reservation.service';

@Injectable({
  providedIn: 'root',
})
export class ReservationQueryParamsGuard implements CanActivate {
  currencies$: Observable<PropertyCurrency[]> = this.propertyService
    .getCurrencies(this.propertyService.property.token)
    .pipe(shareReplay(1));

  constructor(
    @Inject(PropertyService) private readonly propertyService: PropertyService,
    @Inject(ReservationService)
    private readonly reservation: ReservationService,
    @Inject(Router) private readonly router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const checkIn = (() => {
      if (!route.queryParamMap.has('checkIn')) {
        return moment().format('YYYY-MM-DD');
      }
      const checkIn = route.queryParamMap.get('checkIn');
      if (moment(checkIn, 'YYYY-MM-DD').isBefore(moment(), 'day')) {
        return moment().format('YYYY-MM-DD');
      }
      return checkIn;
    })();
    const checkOut =
      (() => {
        if (!route.queryParamMap.has('checkOut')) {
          return moment(checkIn, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD')
        }
        const checkOut = route.queryParamMap.get('checkOut');
        if (moment(checkOut, 'YYYY-MM-DD').isSameOrBefore(moment(checkIn, 'YYYY-MM-DD'), 'day')) {
          return moment(checkIn, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD')
        }
        return checkOut;
      })();

    if (!this.propertyService.property.currency) {
      this.router.navigateByUrl('/property-not-configured');
      return false;
    }
    this.reservation.checkIn = checkIn;
    this.reservation.checkOut = checkOut;
    if (
      ['checkIn', 'checkOut'].some(
        (queryParamName) => !route.queryParamMap.has(queryParamName)
      )
    ) {
      this.router.navigate([state.url], {
        queryParamsHandling: 'merge',
        queryParams: {
          checkIn,
          checkOut,
          ...(!this.propertyService.property.configuredDomain && {
            token: this.propertyService.property.token,
          }),
        },
      });
      return false;
    }
    return true;
  }
}
