import { Title } from '@angular/platform-browser';
import { PropertyService } from './services';
import { colord } from 'colord';
import { ThemeService } from './services/theme.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { HttpClient } from '@angular/common/http';
import { catchError, map, mapTo, switchMap, take } from 'rxjs/operators';
import { CustomerService } from './services/customer/customer.service';
import { ReservationService } from './services/reservation/reservation.service';
import { environment } from '../environments/environment';
import { Observable, of } from 'rxjs';
import { SessionService } from './services/session.service';

export function appInitializer(
  propertyService: PropertyService,
  document: Document,
  title: Title,
  theme: ThemeService,
  http: HttpClient,
  customerService: CustomerService,
  googleTagManager: GoogleTagManagerService,
  ip$: Observable<string | null>,
  session: SessionService
) {
  return () =>
    new Promise((resolve) => {
      const url = new URL(document.defaultView.location.href);
      const currency = url.searchParams.get('currency');
      let observable$: Observable<string>;
      if (!currency) {
        observable$ = ip$.pipe(
          switchMap((ip) => {
            const countryIps: Record<string, string> = {
              CL: '131.221.32.33',
              AR: '170.79.182.146',
              CO: '181.129.181.99',
              CR: '168.228.51.197',
              MX: '186.96.174.169',
              PE: '190.89.28.14',
              US: '173.244.200.156',
            };
            const country = url.searchParams.get('country');
            ip = countryIps[country] ?? ip;
            if (!ip) {
              return of(null);
            }
            return http
              .get<any>(`${environment.url_api}/geolocation-info`, {
                params: { ip },
              })
              .pipe(
                map(
                  ({ geoplugin_currencyCode = null }) => geoplugin_currencyCode
                ),
                catchError(() => of(null))
              );
          })
        );
      } else {
        observable$ = of(currency);
      }
      observable$
        .pipe(
          switchMap((currency) => {
            let params: Record<string, string>;
            customerService.currency = currency;
            if (url.searchParams.has('token')) {
              params = { codigo: url.searchParams.get('token') };
            } else {
              params = { url: url.origin };
            }
            return propertyService.getProperty(params);
          }),
          switchMap((property) =>
            ip$.pipe(
              take(1),
              switchMap((ip) =>
                session.createSession(property.id, { ip_address: ip })
              ),
              mapTo(property),
              catchError(() => of(property))
            )
          )
        )
        .subscribe((property) => {
          propertyService.property = property;
          if (
            property.configuredDomain &&
            !property.domain.includes(document.defaultView.location.hostname)
          ) {
            document.defaultView.location.href = property.domain;
          }
          title.setTitle(property.name);

          const primaryPalette = createPalette(`#${property.primaryColor}`);
          const accentPalette = createPalette(`#${property.accentColor}`);
          const css = getCSSWithDefinedVariables(primaryPalette, accentPalette);
          theme.addCSSToDOM(css);
          if (property.googleTagManagerId) {
            googleTagManager['config'].id = property.googleTagManagerId;
            googleTagManager.addGtmToDom();
          }
        })
        .add(resolve);
    });
}

function createPalette(color: string) {
  const white = '#fff';
  const black = '#000';
  const baseDark = multiply(color, color);

  const palette = {
    50: mix(color, white, 12),
    100: mix(color, white, 30),
    200: mix(color, white, 50),
    300: mix(color, white, 70),
    400: mix(color, white, 85),
    500: mix(color, white, 100),
    600: mix(color, baseDark, 87),
    700: mix(color, baseDark, 70),
    800: mix(color, baseDark, 54),
    900: mix(color, baseDark, 25),
    A100: colord(mix(black, baseDark, 15)).saturate(80).lighten(65).toHex(),
    A200: colord(mix(black, baseDark, 15)).saturate(80).lighten(55).toHex(),
    A400: colord(mix(black, baseDark, 15)).saturate(100).lighten(45).toHex(),
    A700: colord(mix(black, baseDark, 15)).saturate(100).lighten(40).toHex(),
  };

  const contrast = [
    '50',
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
    'A100',
    'A200',
    'A400',
    'A700',
  ].reduce((store, act) => {
    store[act] = getContrast(palette[act]);
    return store;
  }, {});

  return { ...palette, contrast };
}

function multiply(color1: string, color2: string): string {
  const r: number = Math.floor(
    (colord(color1).toRgb().r * colord(color2).toRgb().r) / 255
  );
  const g: number = Math.floor(
    (colord(color1).toRgb().g * colord(color2).toRgb().g) / 255
  );
  const b: number = Math.floor(
    (colord(color1).toRgb().b * colord(color2).toRgb().b) / 255
  );
  return colord(`rgb(${r}, ${g}, ${b})`).toHex();
}

function getContrast(color: string) {
  return colord(color).isLight() ? '#000' : '#fff';
}

function mix(color1: string, color2: string, weight: number) {
  const weightScale = weight / 100;
  const normalizedWeight = weightScale * 2 - 1;
  const alphaDistance = colord(color1).toRgb().a - colord(color2).toRgb().a;

  const combinedWeight1 =
    normalizedWeight * alphaDistance == -1
      ? normalizedWeight
      : (normalizedWeight + alphaDistance) /
        (1 + normalizedWeight * alphaDistance);
  const weight1 = (combinedWeight1 + 1) / 2;
  const weight2 = 1 - weight1;

  return colord(`rgba(
      ${Math.round(
        colord(color1).toRgb().r * weight1 + colord(color2).toRgb().r * weight2
      )},
      ${Math.round(
        colord(color1).toRgb().g * weight1 + colord(color2).toRgb().g * weight2
      )},
      ${Math.round(
        colord(color1).toRgb().b * weight1 + colord(color2).toRgb().b * weight2
      )},
      ${
        colord(color1).toRgb().a * weightScale +
        colord(color2).toRgb().a * (1 - weightScale)
      })`).toHex();
}

function getCSSWithDefinedVariables(
  primaryPalette: any,
  accentPalette: any
): string {
  return `
  :root {
    --color-primary-50: ${primaryPalette[50]};
    --color-primary-100: ${primaryPalette[100]};
    --color-primary-200: ${primaryPalette[200]};
    --color-primary-300: ${primaryPalette[300]};
    --color-primary-400: ${primaryPalette[400]};
    --color-primary-500: ${primaryPalette[500]};
    --color-primary-600: ${primaryPalette[600]};
    --color-primary-700: ${primaryPalette[700]};
    --color-primary-800: ${primaryPalette[800]};
    --color-primary-900: ${primaryPalette[900]};
    --color-primary-A100: ${primaryPalette['A100']};
    --color-primary-A200: ${primaryPalette['A200']};
    --color-primary-A400: ${primaryPalette['A400']};
    --color-primary-A700: ${primaryPalette['A700']};

    --color-accent-50: ${accentPalette[50]};
    --color-accent-100: ${accentPalette[100]};
    --color-accent-200: ${accentPalette[200]};
    --color-accent-300: ${accentPalette[300]};
    --color-accent-400: ${accentPalette[400]};
    --color-accent-500: ${accentPalette[500]};
    --color-accent-600: ${accentPalette[600]};
    --color-accent-700: ${accentPalette[700]};
    --color-accent-800: ${accentPalette[800]};
    --color-accent-900: ${accentPalette[900]};
    --color-accent-A100: ${accentPalette['A100']};
    --color-accent-A200: ${accentPalette['A200']};
    --color-accent-A400: ${accentPalette['A400']};
    --color-accent-A700: ${accentPalette['A700']};

    --contrast-color-primary-50: ${primaryPalette['contrast'][50]};
    --contrast-color-primary-100: ${primaryPalette['contrast'][100]};
    --contrast-color-primary-200: ${primaryPalette['contrast'][200]};
    --contrast-color-primary-300: ${primaryPalette['contrast'][300]};
    --contrast-color-primary-400: ${primaryPalette['contrast'][400]};
    --contrast-color-primary-500: ${primaryPalette['contrast'][500]};
    --contrast-color-primary-600: ${primaryPalette['contrast'][600]};
    --contrast-color-primary-700: ${primaryPalette['contrast'][700]};
    --contrast-color-primary-800: ${primaryPalette['contrast'][800]};
    --contrast-color-primary-900: ${primaryPalette['contrast'][900]};
    --contrast-color-primary-A100: ${primaryPalette['contrast']['A100']};
    --contrast-color-primary-A200: ${primaryPalette['contrast']['A200']};
    --contrast-color-primary-A400: ${primaryPalette['contrast']['A400']};
    --contrast-color-primary-A700: ${primaryPalette['contrast']['A700']};

    --contrast-color-accent-50: ${accentPalette['contrast'][50]};
    --contrast-color-accent-100: ${accentPalette['contrast'][100]};
    --contrast-color-accent-200: ${accentPalette['contrast'][200]};
    --contrast-color-accent-300: ${accentPalette['contrast'][300]};
    --contrast-color-accent-400: ${accentPalette['contrast'][400]};
    --contrast-color-accent-500: ${accentPalette['contrast'][500]};
    --contrast-color-accent-600: ${accentPalette['contrast'][600]};
    --contrast-color-accent-700: ${accentPalette['contrast'][700]};
    --contrast-color-accent-800: ${accentPalette['contrast'][800]};
    --contrast-color-accent-900: ${accentPalette['contrast'][900]};
    --contrast-color-accent-A100: ${accentPalette['contrast']['A100']};
    --contrast-color-accent-A200: ${accentPalette['contrast']['A200']};
    --contrast-color-accent-A400: ${accentPalette['contrast']['A400']};
    --contrast-color-accent-A700: ${accentPalette['contrast']['A700']};
  }
  `;
}
