import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { pluck, catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { APIProperty } from './api-property.interface';
import { APIPropertyResponse } from './api-property-response.interface';
import { Property } from './property.interface';
import { APIPropertyCurrenciesResponse } from './api-property-currencies-response.interface';
import { APIPropertyCurrency } from './api-property-currency.interface';
import { PropertyCurrency } from './property-currency.interface';
import { apiPropertyCurrencyToPropertyCurrency } from './api-property-currency-to-property-currency.function';
import { apiPropertyToProperty } from './api-property-to-property.function';
import { APIPropertyRoomTypesResponse } from './api-property-room-types-response.interface';
import { apiPropertyRoomTypeToPropertyRoomType } from './api-property-room-type-to-property-room-type.function';
import { PropertyRoomType } from './property-room-type.interface';
import { APIRoomTypeWithAvailabilityResponse } from './api-room-type-with-availability-response.interface';
import { apiRoomTypeWithAvailabilityToRoomTypeWithAvailability } from './api-room-type-with-availability-to-room-type-with-availability.function';
import { RoomTypeWithAvailability } from './room-type-with-availability.interface';

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  property: Property;

  constructor(private http: HttpClient) {}

  /** Obtiene todos los datos de la propiedad */
  getProperty(params: Record<string, string>): Observable<Property> {
    return this.http
      .get<APIPropertyResponse>(`${environment.url_api}/motor/propiedades`, {
        params,
      })
      .pipe(pluck('body'), map(apiPropertyToProperty));
  }

  getCurrencies(propertyCode: string): Observable<PropertyCurrency[]> {
    return this.http
      .get<APIPropertyCurrenciesResponse>(
        `${environment.url_api}/motor/propiedades/${propertyCode}/tipos_monedas`
      )
      .pipe(
        pluck('body'),
        map((currencies) =>
          currencies.map(apiPropertyCurrencyToPropertyCurrency)
        )
      );
  }

  getRoomTypes(
    propertyCode: string,
    params: Record<string | number, string> = {}
  ): Observable<PropertyRoomType[]> {
    return this.http
      .get<APIPropertyRoomTypesResponse>(
        `${environment.url_api}/motor/propiedades/${propertyCode}/tipos_habitaciones`,
        { params }
      )
      .pipe(
        pluck('body'),
        map((roomTypes) =>
          roomTypes
            .map(apiPropertyRoomTypeToPropertyRoomType)
            // Para calcular el precio del tipo de habitación de acuerdo al tipo de cambio de la moneda de la propiedad
            // a la moneda del cliente
            .map((roomType) => ({
              ...roomType,
              price: roomType.price * this.property.currencyExchangeRate,
            }))
        )
      );
  }

  getRoomTypesWithAvailability(
    propertyCode: string,
    checkIn: string,
    checkOut: string
  ): Observable<RoomTypeWithAvailability[]> {
    return this.http
      .get<APIRoomTypeWithAvailabilityResponse>(
        `${environment.url_api}/motor/buscar_disponibilidad/${propertyCode}/${checkIn}/${checkOut}`
      )
      .pipe(
        pluck('body'),
        map((roomTypes) =>
          roomTypes
            .map(apiRoomTypeWithAvailabilityToRoomTypeWithAvailability)
            // Para calcular el precio del tipo de habitación de acuerdo al tipo de cambio de la moneda de la propiedad
            // a la moneda del cliente
            .map((room) => ({
              ...room,
              rates: room.rates.map((rate) => ({
                ...rate,
                prices: rate.prices.map((price) => {
                  if (price.dates) {
                    return {
                      ...price,
                      dates: price.dates.map((dateWithPrice) => ({
                        ...dateWithPrice,
                        value:
                          dateWithPrice.value *
                          this.property.currencyExchangeRate,
                      })),
                    };
                  }
                  return {
                    ...price,
                    value: price.value * this.property.currencyExchangeRate,
                  };
                }),
              })),
            }))
        )
      );
  }
}
