import { APIPropertyRoomType } from './api-property-room-type.interface';
import { PropertyRoomType } from './property-room-type.interface';

export function apiPropertyRoomTypeToPropertyRoomType(
  apiPropertyRoomType: APIPropertyRoomType
): PropertyRoomType {
  return {
    id: apiPropertyRoomType.id,
    name:
      apiPropertyRoomType.tipo_hab_canal?.nombre || apiPropertyRoomType.nombre,
    area: apiPropertyRoomType.superficie,
    capacity: apiPropertyRoomType.capacidad,
    price: apiPropertyRoomType.precio_minimo,
    images: apiPropertyRoomType?.imagenes.length
      ? apiPropertyRoomType.imagenes
      : ['assets/images/habitacion-sin-fotografias.jpg'],
  };
}
