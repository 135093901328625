import { APIPropertyCurrency } from './api-property-currency.interface';
import { PropertyCurrency } from './property-currency.interface';

export function apiPropertyCurrencyToPropertyCurrency(
  apiPropertyCurrency: APIPropertyCurrency
): PropertyCurrency {
  return {
    id: apiPropertyCurrency.id,
    name: apiPropertyCurrency.nombre,
    decimals: apiPropertyCurrency.cantidad_decimales || 0,
  };
}
