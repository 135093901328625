import { Component, Inject } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CustomerService } from './services/customer/customer.service';
import { ALERT_DIALOG } from './tokens';
import { AlertDialog } from './interfaces';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  loading$: Observable<boolean> = this.router.events.pipe(
    filter((event) =>
      [NavigationStart, NavigationEnd, NavigationCancel, NavigationError].some(
        (klass) => event instanceof klass
      )
    ),
    map((routerEvent: RouterEvent) => routerEvent instanceof NavigationStart)
  );

  constructor(
    private readonly router: Router,
    googleTagManager: GoogleTagManagerService
  ) {
    router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        googleTagManager.pushTag({
          event: 'page',
          pageName: item.url,
        });
      }
    });
  }
}
