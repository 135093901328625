import { Inject, Injectable } from '@angular/core';
import { Currency } from './currency.interface';
import { getTotalRatePrice } from './get-total-rate-price.function';
import { RoomItem } from './room-item.interface';
import { ExtraItem } from './extra-item.interface';
import { Reservation } from './reservation.interface';
import { reservationToApiReservation } from './reservation-to-api-reservation.function';
import { APIReservation } from './api-reservation.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, pluck, tap } from 'rxjs/operators';
import { APIReservationDetailsResponse } from './api-reservation-details-response.interface';
import { apiReservationDetailsToReservationDetails } from './api-reservation-details-to-reservation-details.function';
import { BehaviorSubject, Observable } from 'rxjs';
import { ReservationDetails } from './reservation-details.interface';
import { IVA } from 'src/app/tokens/iva.token';
import { Coupon } from '../coupon/coupon.interface';
import { PropertyService } from 'services/*';

@Injectable({ providedIn: 'root' })
export class ReservationService {
  get discount(): number {
    return this.roomItems.reduce((acc, item) => {
      return acc + this.getDiscountAmountForItem(item);
    }, 0);
  }

  getDiscountAmountForItem(item: RoomItem) {
    if (!this.coupon || !this.coupon.roomTypeIds.includes(item.roomTypeId)) {
      return 0;
    }
    return (item.price * this.coupon.value) / 100;
  }

  /**
   * Cupón aplicado a esta reserva.
   */
  coupon: Coupon;

  /**
   * Items de la habitaciones de la reserva.
   */
  private _roomItems: BehaviorSubject<RoomItem[]> = new BehaviorSubject<
    RoomItem[]
  >([]);

  get roomItems(): RoomItem[] {
    return this._roomItems.value;
  }

  set roomItems(value: RoomItem[]) {
    this._roomItems.next(value);
  }

  roomItems$: Observable<RoomItem[]> = this._roomItems.asObservable();

  /**
   * Items de la habitaciones de la reserva.
   */
  private _extraItems: BehaviorSubject<ExtraItem[]> = new BehaviorSubject<
    ExtraItem[]
  >([]);

  get extraItems(): ExtraItem[] {
    return this._extraItems.value;
  }

  set extraItems(value: ExtraItem[]) {
    this._extraItems.next(value);
  }

  extraItems$: Observable<ExtraItem[]> = this._extraItems.asObservable();

  /**
   * Detalles de la reserva cuando se ha creado.
   */
  details: ReservationDetails;

  /**
   * En formato YYYY-MM-DD.
   */
  checkIn: string;

  /**
   * En formato YYYY-MM-DD.
   */
  checkOut: string;

  get subTotal(): number {
    return this.roomsSubTotal + this.extrasSubTotal;
  }

  get tax(): number {
    return this.roomsTax + this.extrasTax;
  }

  get total(): number {
    return this.subTotal + this.tax;
  }

  get extrasSubTotal(): number {
    return this.extraItems
      .map(({ price }) => price)
      .reduce((acc, act) => acc + act, 0);
  }

  get extrasTax(): number {
    return this.extraItems
      .map(({ price }) => price * this.iva)
      .map((price) =>
        price.toFixed(this.propertyService.property.currency.decimals)
      )
      .map(parseFloat)
      .reduce((acc, act) => acc + act, 0);
  }

  get extrasTotal(): number {
    return this.extrasSubTotal + this.extrasTax;
  }

  get roomsSubTotal(): number {
    return (
      this.roomItems
        .map(({ price }) => price)
        .reduce((acc, act) => acc + act, 0) - this.discount
    );
  }

  get roomsTax(): number {
    return this.roomItems
      .map(
        (item) => (item.price - this.getDiscountAmountForItem(item)) * this.iva
      )
      .map((price) =>
        price.toFixed(this.propertyService.property.currency.decimals)
      )
      .map(parseFloat)
      .reduce((acc, act) => acc + act, 0);
  }

  get roomsTotal(): number {
    return this.roomsSubTotal + this.roomsTax;
  }

  constructor(
    @Inject(HttpClient) private readonly http: HttpClient,
    @Inject(IVA) private readonly iva: number,
    @Inject(PropertyService) private readonly propertyService: PropertyService
  ) {}

  deleteRoomItem(id: number) {
    this.roomItems = this.roomItems.filter((item) => item.id !== id);
  }

  addRoomItem(item: RoomItem) {
    this.roomItems = [...this.roomItems, item];
  }

  deleteExtraItem(id: number): void {
    this.extraItems = this.extraItems.filter((item) => item.id !== id);
  }

  addExtraItem(item: ExtraItem) {
    this.extraItems = [...this.extraItems, item];
  }

  createReservation(propertyToken: string, reservation: Reservation) {
    const body: APIReservation = reservationToApiReservation(reservation);

    return this.http.post<any>(
      `${environment.url_api}/motor/propiedades/${propertyToken}/reservar`,
      body
    );
  }

  getReservationDetails(
    propertyToken: string,
    reservationDetailsId: number
  ): Observable<ReservationDetails> {
    return this.http
      .get<APIReservationDetailsResponse>(
        `${environment.url_api}/motor/propiedades/${propertyToken}/detalles_reserva/${reservationDetailsId}`
      )
      .pipe(pluck('body'), map(apiReservationDetailsToReservationDetails));
  }

  getPaymentInfo(reservationDetailsId: number) {
    return this.http.get(
      `${environment.url_api}/motor/webpay/obtener_informacion_pago/${reservationDetailsId}`
    );
  }
}
