import { APIReservation } from './api-reservation.interface';
import { Reservation } from './reservation.interface';
import { groupBy, mapValues, values } from 'lodash-es';
import { PaymentMethods } from '../../enums';

export function reservationToApiReservation(
  reservation: Reservation
): APIReservation {
  return {
    tipos_habitaciones: (reservation.roomItems || []).map(
      ({ guests, rateId, roomTypeId }) => ({
        ocupacion: guests,
        tarifa_id: rateId,
        tipo_habitacion_id: roomTypeId,
      })
    ),
    extras: (reservation.extraItems || []).map(
      ({ guests, extraId, date, scheduleId }) => ({
        fecha: date,
        id: extraId,
        cantidad_personas: guests,
        ...(scheduleId && { horario_id: scheduleId }),
      })
    ),
    cliente: {
      rut: reservation.customer.rut,
      nombre:
        reservation.customer.firstName || reservation.customer.businessName,
      ...(reservation.customer.lastName && {
        apellido: reservation.customer.lastName,
      }),
      ...(reservation.customer.order && {
        giro: reservation.customer.order,
      }),
      ciudad: reservation.customer.city,
      pais_id: reservation.customer.countryId,
      region_id: reservation.customer.regionId,
      direccion: reservation.customer.address,
      email: reservation.customer.email,
      telefono: reservation.customer.phone,
      tipo_cliente_id: reservation.customer.customerTypeId,
    },
    captcha: reservation.captcha,
    fecha_fin: reservation.checkOut,
    fecha_inicio: reservation.checkIn,
    metodo_pago_motor_id: (() => {
      switch (reservation.paymentMethodId) {
        case PaymentMethods.CREDIT_CARD:
        case PaymentMethods.DEBIT_CARD:
          return PaymentMethods.DEBIT_OR_CREDIT_CARD;

        case PaymentMethods.DLOCAL_CREDIT_CARD:
        case PaymentMethods.DLOCAL_DEBIT_CARD:
          return PaymentMethods.DLOCAL;
      }
      return reservation.paymentMethodId;
    })(),
    observacion: reservation.observation,
    tipo_moneda_id: reservation.currencyId,
    hora_aproximada_llegada: reservation.estimatedArrivalTime,
    ...(reservation.couponIds && {
      cupones_tipos_habitaciones: reservation.couponIds,
    }),
  };
}
