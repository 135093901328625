import { APIRoomTypeWithAvailability } from './api-room-type-with-availability.interface';
import {
  RoomTypeRatePrice,
  RoomTypeWithAvailability,
} from './room-type-with-availability.interface';

export function apiRoomTypeWithAvailabilityToRoomTypeWithAvailability(
  apiRoomType: APIRoomTypeWithAvailability
): RoomTypeWithAvailability {
  return {
    id: apiRoomType.id,
    name: apiRoomType.tipo_hab_canal?.nombre || apiRoomType.nombre,
    amenities: (apiRoomType.amenidades || []).map(({ nombre }) => nombre),
    area: apiRoomType.superficie,
    availability: apiRoomType.disponibilidad,
    capacity: apiRoomType.capacidad,
    description: apiRoomType.descripcion,
    images: apiRoomType?.imagenes.length
      ? apiRoomType.imagenes
      : ['assets/images/habitacion-sin-fotografias.jpg'],
    rates: (apiRoomType.tarifas_disponibles || []).map(
      ({ tipo_cobro_id, descripcion, nombre, id, precios, restriccion }) => ({
        id,
        chargeTypeId: tipo_cobro_id,
        description: descripcion,
        name: nombre,
        prices: (precios || []).map(
          ({ valor, fecha, cantidad_huespedes, fechas }): RoomTypeRatePrice => {
            if (!!cantidad_huespedes) {
              return {
                guests: cantidad_huespedes,
                dates: (fechas || []).map(({ valor, fecha }) => {
                  return {
                    date: fecha,
                    value: valor,
                  };
                }),
              };
            }
            return {
              date: fecha,
              value: valor,
            };
          }
        ),
        restriction: restriccion && {
          maximumStay: restriccion.estadia_maxima,
          minimumStay: restriccion.estadia_minima,
          anticipationDays: restriccion.dias_anticipacion,
        },
      })
    ),
  };
}
