import { APIProperty } from './api-property.interface';
import { Property } from './property.interface';
import * as moment from 'moment';
import { flattenDeep, orderBy } from 'lodash-es';
import { PaymentMethods } from '../../enums';

export function apiPropertyToProperty(apiProperty: APIProperty): Property {
  const deactivateGoFeelsPayModule = orderBy(
    apiProperty.modules || [],
    ({ pivot: { created_at } }) => moment(created_at).valueOf(),
    'desc'
  ).find(({ id }) => id === 8 /*Desactivar GoFeels Pay*/);
  return {
    name: apiProperty.nombre,
    id: apiProperty.id,
    accentColor: apiProperty.color_acentuado,
    primaryColor: apiProperty.color_primario,
    description: apiProperty.descripcion,
    email: apiProperty.email,
    logo: apiProperty.logo ? apiProperty.logo : 'assets/images/logolight.png',
    images: apiProperty.imagenes?.length
      ? apiProperty.imagenes
      : ['assets/images/hotel-sin-fotografias-disponibles.jpg'],
    location: apiProperty.position,
    token: apiProperty.codigo,
    firstPhoneNumber: apiProperty.telefono,
    secondPhoneNumber: apiProperty.telefono_secundario,
    amenities: (apiProperty.amenidades || []).map(
      ({ nombre, imagen, descripcion }) => ({
        name: nombre,
        iconUrl: imagen,
        description: descripcion,
      })
    ),
    socialNetworks: (apiProperty.redes_sociales || []).map((redSocial) => ({
      url: redSocial.pivot.url,
      iconUrl: redSocial.url_icono,
    })),
    propertyPolicies: apiProperty.politicas,
    checkInTime: apiProperty.hora_checkin?.startsWith('00:00')
      ? '23:59:00'
      : apiProperty.hora_checkin || '',
    checkOutTime: apiProperty.hora_checkout?.startsWith('00:00')
      ? '23:59:00'
      : apiProperty.hora_checkout || '',
    openingTime: apiProperty.hora_apertura || '',
    closingTime: apiProperty.hora_cierre || '',
    configuredDomain: !!apiProperty.dominio_configurado,
    domain: apiProperty.dominio,
    cityName: apiProperty.ciudad,
    regionName: apiProperty.region ? apiProperty.region.nombre : null,
    address: apiProperty.direccion,
    countryName: apiProperty.pais.nombre,
    depositPercentage: apiProperty.tipo_deposito_propiedad.valor || 100,
    paymentMethods: apiPaymentMethodsToPaymentMethods(apiProperty.motor),
    iva: apiProperty.pais.iva || 19,
    website: apiProperty.direccion_web || null,
    hasExtrasEnabled: (() => {
      if (!deactivateGoFeelsPayModule) {
        return true;
      }
      return !deactivateGoFeelsPayModule?.pivot?.is_active;
    })(),
    showLocationMap: !apiProperty.ocultar_mapa_motor,
    youtubeVideoId: apiProperty.id_video_youtube || null,
    currency: {
      decimals: apiProperty.tipo_moneda.cantidad_decimales,
      id: apiProperty.tipo_moneda.id,
      name: apiProperty.tipo_moneda.nombre,
    },
    currencyExchangeRate: apiProperty.factor_de_cambio || 1,
    googleTagManagerId: apiProperty.google_tag_manager_id,
    recaptchaPublicKey: apiProperty.recaptcha.public_key,
    badges: (apiProperty.insignias || []).map(({ contenido, tipo }) => ({
      content: contenido,
      type: ({ imagen: 'image', html: 'html' } as const)[tipo],
    })),
    showHeroTitle: !apiProperty.ocultar_titulo_motor,
  };
}

export function apiPaymentMethodsToPaymentMethods(
  apiPaymentMethods: { name: string; id: number }[]
): { name: string; id: number }[] {
  return flattenDeep(
    apiPaymentMethods.map((paymentMethod) => {
      // El método de pago con ID 7 en base de datos es Tarjeta de crédito/débito
      // se debe separar en dos métodos de pago diferentes
      if (paymentMethod.id === PaymentMethods.DEBIT_OR_CREDIT_CARD) {
        return [
          {
            name: 'Tarjeta de crédito',
            id: PaymentMethods.CREDIT_CARD,
          },
          {
            name: 'Tarjeta de débito',
            id: PaymentMethods.DEBIT_CARD,
          },
        ];
      }
      // El método de pago con ID 8 en base de datos es dLocal
      // se debe separar en dos métodos de pago diferentes
      if (paymentMethod.id === PaymentMethods.DLOCAL) {
        return [
          {
            name: 'Tarjeta de crédito',
            id: PaymentMethods.DLOCAL_CREDIT_CARD,
          },
          {
            name: 'Tarjeta de débito',
            id: PaymentMethods.DLOCAL_DEBIT_CARD,
          },
        ];
      }
      return paymentMethod;
    })
  );
}
