import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  PropertyGuard,
  PropertyWithDomainGuard,
  ReservationQueryParamsGuard,
  SuccededReservationGuard,
} from './guards';
import { PropertyNotFoundComponent } from './property-not-found.component';
import { PropertyNotConfiguredComponent } from './property-not-configured/property-not-configured.component';

const routes: Routes = [
  {
    path: 'reservation',
    loadChildren: () =>
      import('./reservation/reservation.module').then(
        (m) => m.ReservationModule
      ),
    canLoad: [PropertyGuard],
    canActivate: [ReservationQueryParamsGuard],
  },
  {
    path: 'success',
    loadChildren: () =>
      import('./success/success.module').then((m) => m.SuccessModule),
    canLoad: [PropertyGuard],
    canActivate: [SuccededReservationGuard],
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./error/error.module').then((m) => m.ErrorModule),
    canLoad: [PropertyGuard],
  },
  { path: 'motor-reserva', pathMatch: 'full', redirectTo: '' },
  { path: 'property-not-found', component: PropertyNotFoundComponent },
  {
    path: 'property-not-configured',
    component: PropertyNotConfiguredComponent,
  },
  {
    path: '',
    loadChildren: () =>
      import('./landing/landing.module').then((m) => m.LandingModule),
    canLoad: [PropertyGuard],
    canActivate: [PropertyWithDomainGuard],
  },
  { path: 'motor-reserva', pathMatch: 'full', redirectTo: 'reservation' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    enableTracing: false,
    paramsInheritanceStrategy: 'always',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
