import { APIReservationDetails } from './api-reservation-details.interface';
import { ReservationDetails } from './reservation-details.interface';
import { PaymentMethods } from '../../enums';

export function apiReservationDetailsToReservationDetails(
  details: APIReservationDetails
): ReservationDetails {
  return {
    checkIn: details.reservas[0]?.checkin,
    checkOut: details.reservas[0]?.checkout,
    createdAt: details.created_at,
    estimatedArrivalTime: details.hora_aproximada_llegada,
    customer: {
      countryName: details.cliente.pais.nombre,
      customerTypeId: details.cliente.tipo_cliente_id,
      email: details.cliente.email,
      phone: details.cliente.telefono,
      regionName: details.cliente.region?.nombre || null,
      rut: details.cliente.rut,
      address: details.cliente.direccion,
      ...(details.cliente.tipo_cliente_id === 2 && {
        businessName: details.cliente.nombre,
        order: details.cliente.giro,
      }),
      ...(details.cliente.tipo_cliente_id === 1 && {
        firstName: details.cliente.nombre,
        lastName: details.cliente.apellido,
      }),
      city: details.cliente.ciudad,
    },
    id: details.id,
    number: details.numero_reserva,
    ...(() => {
      return {
        paymentMethodId:
          details.reservas[0]?.metodo_pago_motor.id ||
          details.extras[0]?.pivot.metodo_pago_motor_id,
        paymentMethodName:
          details.reservas[0]?.metodo_pago_motor.nombre ||
          details.reservas[0]?.metodo_pago_motor.name ||
          details.extras[0]?.pivot.metodo_pago_motor.nombre ||
          details.extras[0]?.pivot.metodo_pago_motor.name,
      };
    })(),
    subTotal: details.monto_neto + details.monto_neto_extras,
    tax: details.monto_iva + details.monto_iva_extras,
    total: details.monto_total,
    discount: details.monto_descuento,
    rooms: (details.reservas || []).map((reserva) => ({
      guests: reserva.ocupacion,
      number: reserva.habitacion ? +reserva.habitacion.nombre : null,
      typeName: reserva.tipo_habitacion.nombre,
      price: reserva.monto_neto,
    })),
    extras: (details.extras || []).map(
      ({
        pivot: { cantidad_personas, monto_total, fecha, horario },
        nombre,
      }) => ({
        guests: cantidad_personas,
        extraName: nombre,
        price: monto_total,
        date: fecha,
        ...(horario && { time: horario.hora }),
      })
    ),
    currency: {
      name: details.tipo_moneda.nombre,
      decimals: details.tipo_moneda.cantidad_decimales,
    },
    depositTotal: details.monto_deposito,
    exchangeRate: (() => {
      const [pago] = details.pagos;
      if (
        pago &&
        pago.metodo_pago_motor_id === PaymentMethods.DEBIT_OR_CREDIT_CARD
      ) {
        return 1;
      }

      return details.tasa_intercambio || 1;
    })(),
  };
}
