import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { PropertyService } from 'services/*';
import { Confirmer } from 'src/app/interfaces';
import { ReservationComponent } from 'src/app/reservation/reservation.component';
import { getTotalRatePrice } from 'src/app/services/reservation/get-total-rate-price.function';
import { ReservationService } from 'src/app/services/reservation/reservation.service';
import { CONFIRMER } from 'src/app/tokens';

@Component({
  selector: 'app-reservation-details-panel',
  templateUrl: './reservation-details-panel.component.html',
})
export class ReservationDetailsPanelComponent implements OnInit {
  @Input() readonly = false;

  numberOfNightsPluralMap: Record<string, string> = {
    '=1': 'Una noche',
    other: '# noches',
  };

  get discount(): number {
    return this.reservation.discount;
  }

  get depositPercentage(): number {
    return this.propertyService.property.depositPercentage;
  }

  get selectedCurrencyName(): string {
    return this.propertyService.property.currency?.name || '';
  }

  get selectedCurrencyDecimals(): number {
    return this.propertyService.property.currency?.decimals || 0;
  }

  get currencyDecimalsDigitsInfo(): string {
    return `1.${this.selectedCurrencyDecimals}-${this.selectedCurrencyDecimals}`;
  }

  get checkInTime(): string {
    return this.propertyService.property.checkInTime.slice(0, 5);
  }

  get checkOutTime(): string {
    return this.propertyService.property.checkOutTime.slice(0, 5);
  }

  get openingTime(): string {
    return this.propertyService.property.openingTime.slice(0, 5);
  }

  get closingTime(): string {
    return this.propertyService.property.closingTime.slice(0, 5);
  }

  get checkIn(): string {
    return this.reservation.checkIn;
  }

  get checkOut(): string {
    return this.reservation.checkOut;
  }

  get numberOfNights(): number {
    return moment(this.checkOut, 'YYYY-MM-DD').diff(
      moment(this.checkIn, 'YYYY-MM-DD'),
      'day'
    );
  }

  get roomItems(): {
    roomName: string;
    guests: number;
    price: number;
    id: number;
  }[] {
    return this.reservation.roomItems.map(({ guests, name, price, id }) => ({
      id,
      guests,
      price,
      roomName: name,
    }));
  }

  get extraItems(): {
    extraName: string;
    guests: number;
    price: number;
    id: number;
    date: string;
    time: string;
  }[] {
    return this.reservation.extraItems.map(
      ({ guests, name, price, id, date, time }) => ({
        id,
        guests,
        price,
        date,
        extraName: name,
        time,
      })
    );
  }

  get propertyHasSchedules(): boolean {
    return (
      !!this.checkInTime &&
      !!this.checkOutTime &&
      !!this.openingTime &&
      !!this.closingTime
    );
  }

  trackById = (index: number, item: any) => item.id;

  constructor(
    @Inject(PropertyService) private readonly propertyService: PropertyService,
    @Inject(ReservationService) public readonly reservation: ReservationService
  ) {}

  ngOnInit(): void {}

  deleteRoomItem(id: number): void {
    this.reservation.deleteRoomItem(id);
  }

  deleteExtraItem(id: number): void {
    this.reservation.deleteExtraItem(id);
  }
}
