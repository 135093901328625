import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  PropertyService,
  SwalAlertDialogService,
  SwalConfirmerService,
  SwalLoaderService,
} from 'services/*';

import { SharedModule } from './shared/shared.module';
import { appInitializer } from './app.initializer';
import { DOCUMENT } from '@angular/common';
import { ThemeService } from './services/theme.service';
import { ALERT_DIALOG, CONFIRMER, LOADER, SWAL } from './tokens';
import Swal from 'sweetalert2';
import { PropertyNotFoundComponent } from './property-not-found.component';
import '@angular/common/locales/global/es-CL';
import {
  errorTailorImports,
  provideErrorTailorConfig,
} from '@ngneat/error-tailor';
import { CustomerService } from './services/customer/customer.service';
import { CurrencyInterceptor } from './interceptors';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { PropertyNotConfiguredComponent } from './property-not-configured/property-not-configured.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { SessionService } from './services/session.service';
import { IP } from './tokens/ip.token';

@NgModule({
  declarations: [
    AppComponent,
    PropertyNotFoundComponent,
    PropertyNotConfiguredComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    errorTailorImports,
    HttpClientJsonpModule,
    MatProgressBarModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: appInitializer,
      deps: [
        PropertyService,
        DOCUMENT,
        Title,
        ThemeService,
        HttpClient,
        CustomerService,
        GoogleTagManagerService,
        IP,
        SessionService,
      ],
    },
    {
      provide: SWAL,
      useValue: Swal.mixin({
        heightAuto: false,
      }),
    },
    {
      provide: CONFIRMER,
      useExisting: SwalConfirmerService,
    },
    {
      provide: LOADER,
      useExisting: SwalLoaderService,
    },
    {
      provide: ALERT_DIALOG,
      useExisting: SwalAlertDialogService,
    },
    {
      provide: LOCALE_ID,
      useValue: 'es-CL',
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: CurrencyInterceptor,
    },
    { provide: 'googleTagManagerId', useValue: 'GTM-K8JM6HT' },
    provideErrorTailorConfig({
      errors: {
        useValue: {
          required: 'Campo obligatorio',
          email: 'Correo electrónico inválido',
          phone: 'Número de teléfono inválido',
          firstNameOrLastName: "Sólo debe contener letras o símbolos (,.'-)",
          minlength: ({ requiredLength, actualLength }) =>
            `Mínimo ${requiredLength} caracteres`,
          pattern: ({
            requiredPattern,
          }: {
            requiredPattern: string;
            actualValue: string;
          }) => {
            switch (requiredPattern) {
              case '^[a-zA-Z0-9]*$':
                return 'El campo no debe contener puntos ni guion';
              default:
                return 'Patrón incorrecto';
            }
          },
        },
      },
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
